var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","show-select":true,"single-select":false,"item-key":"idMensagem","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
                      disableItemsPerPage: false,
                      itemsPerPageOptions: [_vm.itemsPerPage],
                      },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[(_vm.pesquisou)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" "+_vm._s(_vm.erroBuscaClientes == ''? 'Nenhum Aluno encontrado.' : _vm.erroBuscaClientes)+" ")]):_vm._e(),(!_vm.pesquisou)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" Informe pelo menos um item do filtro para selecionar quem receberá a mensagem. ")]):_vm._e()],1)],1)]},proxy:true},{key:"item.idCliente",fn:function(ref){
                      var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.idCliente))])]}},{key:"item.dtNascimento",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.dtNascimento,'DD/MM/YYYY')))])]}},{key:"item.sexo",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.sexo=='M' ? 'Masculino' : 'Feminino'))])]}},{key:"item.dtVencFaturas",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.dtVencFaturas,'DD/MM/YYYY')))])]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
                      var items = ref.items;
                      var isSelected = ref.isSelected;
                      var select = ref.select;
return [_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[(items.length === 0 && _vm.pesquisou)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" "+_vm._s(_vm.erroBuscaClientes == ''? 'Nenhum Aluno encontrado.' : _vm.erroBuscaClientes)+" ")]):_vm._e(),(items.length === 0 && !_vm.pesquisou)?_c('v-alert',{attrs:{"border":"bottom","color":"blue-grey","dark":""}},[_vm._v(" Informe pelo menos um item do filtro para selecionar quem receberá a mensagem. ")]):_vm._e()],1)],1),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.idCliente,staticClass:"list-item"},[_c('v-list-item-avatar',[_c('strong',[_vm._v("#"+_vm._s(item.idCliente))])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nomeCliente)}})],1)],1)}),1)]}}:null],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }