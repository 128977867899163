<template>
  <div>
    <v-data-table :headers="headers"
                  dense
                  :show-select="true"
                  :single-select="false"
                  item-key="idMensagem"
                  v-model="selection"
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                        disableItemsPerPage: false,
                        itemsPerPageOptions: [itemsPerPage],
                        }"
                  :items="elements" :loading="loading">
      <template v-slot:no-data>
        <v-card class="elevation-0">
          <v-card-text class="text-center">
            <v-alert v-if="pesquisou" border="bottom" color="blue-grey" dark >
               {{erroBuscaClientes == ''? 'Nenhum Aluno encontrado.' : erroBuscaClientes}}
            </v-alert>
            <v-alert v-if="!pesquisou" border="bottom" color="blue-grey" dark >
              Informe pelo menos um item do filtro para selecionar quem receberá a mensagem.
            </v-alert>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:item.idCliente="{item}">
        <strong>#{{item.idCliente}}</strong>
      </template>
      <template v-slot:item.dtNascimento="{item}">
        <span>{{item.dtNascimento | moment('DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.sexo="{item}">
        <span>{{ item.sexo=='M' ? 'Masculino' : 'Feminino'}}</span>
      </template>
      <template v-slot:item.dtVencFaturas="{item}">
        <span>{{item.dtVencFaturas | moment('DD/MM/YYYY')}}</span>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card class="elevation-0">
          <v-card-text class="text-center">
            <v-alert v-if="items.length === 0 && pesquisou" border="bottom" color="blue-grey" dark >
              {{erroBuscaClientes == ''? 'Nenhum Aluno encontrado.' : erroBuscaClientes}}
            </v-alert>
            <v-alert v-if="items.length === 0 && !pesquisou" border="bottom" color="blue-grey" dark >
              Informe pelo menos um item do filtro para selecionar quem receberá a mensagem.
            </v-alert>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.idCliente" class="list-item">
            <v-list-item-avatar>
              <strong>#{{item.idCliente}}</strong>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.nomeCliente"/>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {findClientes} from '../../../api/mensagens';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MensagensDataTable",
  props: {
    searchValues: {
      type: Object
    }
  },
  data() {
    return {
      selection: [],
      selectedItem: null,
      loading: false,
      error: null,
      pesquisou: false,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      erroBuscaClientes: '',
      headers: [
        {
          value: 'idCliente',
          text: 'ID',
          align: 'start'
        },          
        {
          value: 'nomeCliente',
          text: 'Nome',
          align: 'start',
          width: "20%"
        },
        {
          value: 'dtNascimento',
          text: 'Data. Nascimento',
          align: 'start'
        }, 
        {
          value: 'sexo',
          text: 'Sexo',
          align: 'start'
        },       
        {
          value: 'descricaoLocal',
          text: 'Local',
          align: 'start'
        },
        {
          value: 'descricaoCompeticao',
          text: 'Torneio',
          align: 'start'
        },
        {
          value: 'dtVencFaturas',
          text: 'Venc. Fatura',
          align: 'start'
        },
        {
          value: 'descricaoFaturas',
          text: 'Desc. Fatura',
          align: 'start'
        }
      ]
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.nome
    }
  },
  watch: {
    selection: {
      handler() {
        this.$emit('update:selection', this.selection);
      }
    },
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    async refresh() {
      try {
        
        this.error = null;
        this.loading = true;
        const page = await findClientes(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.erroBuscaClientes = page.msgRetorno;
        this.pageOptions.page = page.currentPage;
        this.pesquisou = true;
        this.selection = [];

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    //this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 72px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>