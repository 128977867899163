<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="1200px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
      <v-form ref="form" @submit.prevent="save" 
        v-model="formValid" lazy-validation>
        <mensagem-form v-model="item"
        :clientes-selecionados="clientesSelecionados"
        :search-values="searchValues"
        />
      </v-form>

    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import MensagemForm from "../forms/MensagemForm";
import {createPorGrupo} from "../../../api/mensagens";
import {ApiError} from "../../../api/core";
export default {
  name: "CreateEditMensagemDialog",
  components: {MensagemForm},
  props: [
      'value',
      'item',
      'clientesSelecionados',
      'searchValues'
    ],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return 'Adicionar Mensagem(ns)'
    }
  },
  methods: {
    async save() {
      try {
        
        this.item.idClientes = this.clientesSelecionados.join(',')
        this.item.tipoComunicacao = this.searchValues.tipoComunicacao

        if(this.$refs.form.validate()) {
          await createPorGrupo(this.item);
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Envio da(s) Mensagem(ns) programada(s) com sucesso.`, {
            timeout: 3000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível programar o envio da(s) Mensagem(ns).`, {
            timeout: 6000
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>