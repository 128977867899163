var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.tipoComunicacaoSelecionado))]),_vm._v(" - "),_c('strong',[_vm._v(_vm._s(_vm.qtdClientesSelecionados))])]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Informe um assunto para essa(s) mensagem(ns)","dense":"","value":"","rules":[
                    this.$validators.string.required
                ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('lookup-dominio',{attrs:{"label":"Enviar por","type":_vm.lookups.tipoEnvioMensagem,"hidden-clear":true,"rules":[
                        this.$validators.string.required
                    ]},model:{value:(_vm.value.tipoEnvioMensagem),callback:function ($$v) {_vm.$set(_vm.value, "tipoEnvioMensagem", $$v)},expression:"value.tipoEnvioMensagem"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
              this.$validators.string.required ]},model:{value:(_vm.value.vaiEnviarImagem),callback:function ($$v) {_vm.$set(_vm.value, "vaiEnviarImagem", $$v)},expression:"value.vaiEnviarImagem"}},[_c('v-radio',{attrs:{"label":"Sem anexo","value":"N"}}),_c('v-radio',{attrs:{"label":"Com anexo","value":"S"}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.vaiEnviarImagem=='S'),expression:"value.vaiEnviarImagem=='S'"}],attrs:{"cols":"12","md":"8"}},[_c('v-file-input',{attrs:{"accept":".jpg, .jpeg, .png, .gif, .mp4","label":"Escolha uma imagem ou um vídeo de até 20MB","rules":[
          function (v) { return this$1.$validators.number.tamanhoMaxArquivo(v, 20); }
        ]},model:{value:(_vm.value.caminhoImagem),callback:function ($$v) {_vm.$set(_vm.value, "caminhoImagem", $$v)},expression:"value.caminhoImagem"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Texto da mensagem","counter":"","filled":"","prepend-icon":"mdi-comment","rows":"7","rules":[
                    this.$validators.string.required
                ]},model:{value:(_vm.value.mensagem),callback:function ($$v) {_vm.$set(_vm.value, "mensagem", $$v)},expression:"value.mensagem"}})],1),_c('v-col',{staticClass:"text-caption",attrs:{"cols":"12"}},[_c('v-divider'),_c('div',[_vm._v("Ao programar o envio da(s) mensagens(s), você poderá usar uma ou mais chaves abaixo e o sistema substituirá automaticamente pelo valor correspondente a cada linha marcada")]),_c('ul',[_c('li',[_vm._v("{nome} - Nome do Cliente")]),_c('li',[_vm._v("{mes} - Mês referência (boleto ou aniversariante do mês)")]),_c('li',[_vm._v("{vencimento} - Vencimento do boleto")]),_c('li',[_vm._v("{valor} - Valor do boleto")]),_c('li',[_vm._v("{desconto} - Desconto: só é exibido caso o cliente tenha desconto e fatura esteja em dia")])]),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }